// ChatInput.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StandardChatInput = ({
    searchQuery,
    setSearchQuery,
    sendIcon,
    currentTheme,
    sendChatbotMessage,
    setMessages,
    setBotTyping
}) => {
    const handleSubmit = (e) => {
        const trimmedQuery = searchQuery.trim();

        // Check if the trimmed query is empty or contains only symbols
        if (trimmedQuery === "" || /^[^a-zA-Z0-9]+$/.test(trimmedQuery)) {
            // If searchQuery is empty or contains only non-alphanumeric characters
            return; // Block the submission
        }
        e.preventDefault();

        // Add user message immediately to the messages array
        setMessages(prevMessages => [
            ...prevMessages,
            { text: searchQuery, type: 'user' }
        ]);

        // Trigger bot typing animation immediately
        setBotTyping(true);

        // Clear the input field
        setSearchQuery('');

        // Trigger the chatbot search
        sendChatbotMessage(searchQuery); // Sends the query

    };

    return (
        <form
            className="flex items-center p-4 bg-gray-100 rounded-b-lg"
            style={{ backgroundColor: currentTheme.footerBg }}
            onSubmit={handleSubmit} // Attach the submit handler to the form
        >
            <input
                type="text"
                className="flex-grow p-2 mr-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-500"
                value={searchQuery}
                style={{
                    borderColor: currentTheme.inputBorder,
                    color: currentTheme.inputTextColor,
                }}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Type your message here..."
            />
            <button
                type="submit" // Keep the button type as submit to allow form submission
                className="ml-2 p-2 text-white rounded-lg shadow-md"
                style={{
                    backgroundColor: currentTheme.buttonBg,
                }}
                onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = currentTheme.buttonHoverBg)
                }
                onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = currentTheme.buttonBg)
                }
            >
                <FontAwesomeIcon icon={sendIcon} size="lg" />
            </button>
        </form>
    );
};

export default StandardChatInput;
