// ChatInput.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MinimalChatInput = ({
    searchQuery,
    setSearchQuery,
    sendIcon,
    currentTheme,
    sendChatbotMessage,
    setMessages,
    setBotTyping
}) => {
    const handleSubmit = (e) => {
        const trimmedQuery = searchQuery.trim();

        // Check if the trimmed query is empty or contains only symbols
        if (trimmedQuery === "" || /^[^a-zA-Z0-9]+$/.test(trimmedQuery)) {
            // If searchQuery is empty or contains only non-alphanumeric characters
            return; // Block the submission
        }
        e.preventDefault();

        // Add user message immediately to the messages array
        setMessages(prevMessages => [
            ...prevMessages,
            { text: searchQuery, type: 'user' }
        ]);

        // Trigger bot typing animation immediately
        setBotTyping(true);

        // Clear the input field
        setSearchQuery('');

        // Trigger the chatbot search
        sendChatbotMessage(searchQuery); // Sends the query

    };
    return (
        <form
            onSubmit={handleSubmit} // Add the submit handler here
            className="flex items-center p-2 border-t"
        >
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="flex-1 p-2 mb-2 border rounded-lg shadow-md"
                style={{
                    borderColor: currentTheme.inputBorder,
                    color: currentTheme.inputTextColor,
                }}
                placeholder="Type a message..."
            />
            <button
                type="button" // Change to "button" to avoid form submission via button click
                className="ml-2 p-2 mb-2 text-white rounded-lg shadow-md"
                style={{
                    backgroundColor: currentTheme.buttonBg,
                }}
                onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = currentTheme.buttonHoverBg)
                }
                onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = currentTheme.buttonBg)
                }
                onClick={handleSubmit} // Call the submit handler on button click
            >
                <FontAwesomeIcon icon={sendIcon} size="lg" />
            </button>
        </form>
    );
};

export default MinimalChatInput;
