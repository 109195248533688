// ChatInput.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormalChatInput = ({
    searchQuery,
    setSearchQuery,
    sendIcon,
    currentTheme,
    sendChatbotMessage,
    setMessages,
    setBotTyping
}) => {
    const handleSubmit = (e) => {
        const trimmedQuery = searchQuery.trim();

        // Check if the trimmed query is empty or contains only symbols
        if (trimmedQuery === "" || /^[^a-zA-Z0-9]+$/.test(trimmedQuery)) {
            // If searchQuery is empty or contains only non-alphanumeric characters
            return; // Block the submission
        }
        e.preventDefault();

        // Add user message immediately to the messages array
        setMessages(prevMessages => [
            ...prevMessages,
            { text: searchQuery, type: 'user' }
        ]);

        // Trigger bot typing animation immediately
        setBotTyping(true);

        // Clear the input field
        setSearchQuery('');

        // Trigger the chatbot search
        sendChatbotMessage(searchQuery); // Sends the query

    };

    return (
        <form className="flex p-2 items-center bg-white border-t border-gray-200" onSubmit={handleSubmit}>
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Type a message..."
                className="flex-1 py-2 px-4 bg-transparent focus:outline-none"
            />
            <button
                type="submit"
                className="text-white px-4 py-2 rounded-full transition-transform transform hover:scale-105"
                style={{ backgroundColor: currentTheme.buttonBg }}
                onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = currentTheme.buttonHoverBg)
                }
                onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = currentTheme.buttonBg)
                }
            >
                <FontAwesomeIcon icon={sendIcon} />
            </button>
        </form>
    );
};

export default FormalChatInput;
