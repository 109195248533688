import React from 'react';

const MinimalistHeader = ({ currentTheme, botName, avatars }) => {
    return (
        <div className="p-5 rounded-t-lg shadow-md flex flex-col items-center" style={{ backgroundColor: currentTheme.headerBg }}>
            {/* Logo */}
            <img
                src={avatars.botAvatar}
                alt="Company Logo"
                className="h-12 w-12 mb-2"
            />
            {/* Company Name */}
            <span className="text-xl font-bold text-white">{botName}</span>
        </div>
    );
};

export default MinimalistHeader;