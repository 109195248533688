import React, { useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';


const BorderChatArea = ({ messages, botTyping, avatars, currentTheme }) => {
    const messagesEndRef = useRef(null);

    const formatMessageText = (text) => {
        // Regex to find links, words in square brackets, and email addresses
        if (typeof text !== 'string') {
            console.error('Expected a string but got:', typeof text, text);
            return text.text; // or handle accordingly
        }
        // Remove hashtag symbols
        let formattedText = text.replace(/#/g, '');

        // Bold formatting
        const boldTextRegex = /\*\*(.*?)\*\*/g;
        formattedText = formattedText.replace(boldTextRegex, (match, boldText) => `<b>${boldText}</b>`);

        // URL and email detection regex
        const regex = /(\[([^\]]+)\])?\s*(https?:\/\/[^\s]+|www\.[^\s]+|[^\s]+\.[^\s]+|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;

        // Split text by new lines and apply link formatting
        return formattedText.split('\n').map((line) => {
            return line.replace(regex, (match, p1, p2, p3) => {
                let finalLink;

                // Check if p3 is an email
                if (p3.includes('@')) {
                    finalLink = `mailto:${p3.trim()}`;
                    return `<br/><a href="${finalLink}" style="color: ${currentTheme.linkTextColor};"; text-decoration: underline;" target="_blank" rel="noopener noreferrer"><i class="fa fa-envelope" style="color: ${currentTheme.linkTextColor};"></i> ${p3.trim()}</a>`;
                } else {
                    // Clean and process links
                    const cleanLink = p3.replace(/^[^\w]+|[^\w]+$/g, '');
                    finalLink = cleanLink.startsWith('http') || cleanLink.startsWith('www') ? cleanLink : `http://${cleanLink}`;

                    if (p1) {
                        // Use the text in brackets as link text
                        return ` <a href="${finalLink}" style="color: ${currentTheme.linkTextColor}; text-decoration: underline;" target="_blank" rel="noopener noreferrer">${p2}</a>`;
                    }
                    return `<br/><i class="fas fa-link" style="color: ${currentTheme.linkTextColor};"></i> <a href="${finalLink}" style="color: ${currentTheme.linkTextColor}; text-decoration: underline;" target="_blank" rel="noopener noreferrer">${finalLink}</a>`;
                }
            });
        }).join('<br/>'); // Join lines with <br/> for new lines
    };

    // Auto-scroll to the bottom when messages update
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages, botTyping]);

    return (
        <div className="p-4 h-80 overflow-y-auto bg-white shadow-inner">
            {messages.map((message, index) => (
                <div
                    key={index}
                    className={`flex mb-2 ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
                >
                    <div className="w-10 h-10 rounded-full overflow-hidden mr-2 flex-shrink-0">
                        {message.type !== 'user' && (
                            <img
                                src={message.type === 'user' ? avatars.userAvatar : avatars.botAvatar}
                                alt={message.type === 'user' ? 'User Avatar' : 'Bot Avatar'}
                                className="w-full h-full object-cover"
                            />
                        )}

                    </div>
                    <div
                        className={`p-3 border ${message.type === 'user' ? 'rounded-tl-xl rounded-tr-xl rounded-bl-xl' : 'rounded-tr-xl rounded-tl-xl rounded-br-xl'}`}
                        style={{
                            width: '70%',
                            borderColor: message.type === 'user' ? currentTheme.userMessageBg : currentTheme.botMessageBg,
                            color: message.type === 'user' ? currentTheme.userTextColor : currentTheme.botTextColor,
                            backgroundColor: 'transparent',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                        }}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                formatMessageText(message.text)
                            ),
                        }}
                    >

                    </div>
                </div>
            ))}
            {botTyping && (
                <div className="flex mb-2">
                    <div className="w-10 h-10 rounded-full overflow-hidden mr-2">
                        <img
                            src={avatars.botAvatar}
                            alt="Bot Avatar"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div
                        className="p-2 rounded-lg border"
                        style={{
                            borderColor: currentTheme.botMessageBorder,
                            color: currentTheme.botTextColor,
                            backgroundColor: 'transparent',
                        }}
                    >
                        <div className="flex space-x-1">
                            <div className="h-2 w-2 rounded-full animate-bounce" style={{
                                backgroundColor: currentTheme.buttonBg,
                            }}></div>
                            <div className="h-2 w-2 rounded-full animate-bounce" style={{
                                backgroundColor: currentTheme.buttonBg,
                            }}></div>
                            <div className="h-2 w-2 rounded-full animate-bounce" style={{
                                backgroundColor: currentTheme.buttonBg,
                            }}></div>
                        </div>
                    </div>
                </div>
            )}

            <div ref={messagesEndRef} />
        </div>
    );
};

export default BorderChatArea;