import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chatbot from "./components/Chatbot";
import DevMode from './components/DevMode';

export default function App() {
  const devMode = false;
  return (
    <>
      <div className="fixed bottom-0 right-4 z-50">
        <Router>
          {devMode ? ( // Conditionally render DevModePage
            <DevMode />
          ) : (
            <>
              <Routes>
                <Route path="/"></Route>
                <Route path="/chatBot/:chatbotId" element={<Chatbot />} />
              </Routes>
            </>)}
        </Router>
      </div>
    </>
  );
}