import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { themes, colors, images } from './Theme';
import Sidebar from './SideBar';
import { globalIcons, templates } from './Theme';
import BasicChatArea from '../Templates/ChatAreaTemplates/BasicChatArea';
import StandardChatArea from '../Templates/ChatAreaTemplates/StandardChatArea';
import BasicHeader from '../Templates/Header/basicHeader';
import GrandHeader from '../Templates/Header/GrandHeader';
import StandardHeader from '../Templates/Header/StandardHeader';
import GradientHeader from '../Templates/Header/GradientHeader';
import BasicChatInput from '../Templates/inputBoxTemplates/BasicInputBox';
import FormalChatInput from '../Templates/inputBoxTemplates/FormalInputBox';
import MinimalChatInput from '../Templates/inputBoxTemplates/MinimalInputBox';
import StandardChatInput from '../Templates/inputBoxTemplates/StandardInputBox';
import MinimalHeader from '../Templates/Header/MinimalistHeader'; // Added import
import IconicHeader from '../Templates/Header/IconicHeader'; // Added import
import WaveHeader from '../Templates/Header/WaveHeader'; // Added import
import DiagonalHeader from '../Templates/Header/DiagonalHeader'; // Added import
import CurvedGradientHeader from '../Templates/Header/CurvedGradientHeader'; // Added import
import DarkBoldWaveHeader from '../Templates/Header/DarkBoldWaveHeader'; // Added import
import SimpleHeader from '../Templates/Header/SimpleHeader'; // Added import
import ChatHeader from '../Templates/Header/ChatHeader'; // Added import
import BubbleShapedChatArea from '../Templates/ChatAreaTemplates/BubbleShapedChatArea'; // Added import
import BorderChatArea from '../Templates/ChatAreaTemplates/BorderChatArea'; // Added import
import Border2ChatArea from '../Templates/ChatAreaTemplates/Border2ChatArea'; // Added import
import TriangleStyle from '../Templates/ChatAreaTemplates/TriangleStyle'; // Added import
import StepIndicator from './StepIndicator';
import Cookies from 'js-cookie';

const CustomizeAIChatBotDesign = () => {
    // Split state into individual pieces for real-time updates
    const [headerId, setHeaderId] = useState('Basic');
    const [messageAreaId, setMessageAreaId] = useState('Basic');
    const [inputBoxId, setInputBoxId] = useState('Basic');
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchError, setSearchError] = useState(null);
    const [messages, setMessages] = useState([]);
    const [botTyping, setBotTyping] = useState(false);
    const messagesEndRef = useRef(null);
    const [currentTheme, setCurrentTheme] = useState(colors);
    const [chatbotWidth, setChatbotWidth] = useState('400px');
    const [sendIcon, setSendIcon] = useState(globalIcons.sendIcon);
    const [avatars, setAvatars] = useState(images);
    const [customThemes, setCustomThemes] = useState([]);
    const [currentStep, setCurrentStep] = useState(4);
    const [initialized, setInitialized] = useState(true)
    const [botLoading, setIsLoading] = useState(true);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [botName, setBotName] = useState(true);
    const location = useLocation()
    const [tagline, setTagLine] = useState('I am an Assistant Bot')

    // Function to get query parameters
    const getQueryParams = (param) => {
        const queryParams = new URLSearchParams(location.search);
        console.log(param + ' ' + queryParams.get(param))
        return queryParams.get(param);
    };

    const handleAvatarChange = (avatarType, newAvatar) => {
        setAvatars((prevAvatars) => ({
            ...prevAvatars,
            [avatarType]: newAvatar,
        }));
    };


    // Access UID and Token
    const uid = getQueryParams('uid'); // Retrieves the uid
    const apiToken = getQueryParams('apiToken'); // Retrieves the token
    const chatbotId = getQueryParams('chatbotId'); // Retrieves the token

    useEffect(() => {
        console.log('Location Object:', location);
        console.log('Query Params:', location.search);
    }, [location]);

    const sendChatbotMessage = async (query) => {
        try {
            const response = await fetch("https://api-bny5h3g2lq-uc.a.run.app/chatbotMessage", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    chatbotId,
                    uid,
                    apiToken,
                    query,
                }),
            });

            // Check if the response is OK before parsing it
            if (!response.ok) {
                const errorData = await response.json();
                console.error("Failed to send message:", errorData);
                return; // Exit the function to prevent further execution
            }

            // Parse the JSON response
            const data = await response.json();

            // Set search results
            setSearchResults(data.response); // Use 'data' instead of 'response.data'

            // Simulate bot typing
            setTimeout(() => {
                const sanitizedResponse = DOMPurify.sanitize(data.response); // Sanitize the actual response data
                localStorage.setItem("sample", sanitizedResponse);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: sanitizedResponse, type: 'bot' }
                ]);
                setBotTyping(false);
            }, 1000);

        } catch (error) {
            console.error("Fetch error:", error);
        }
    };



    const commonTemplateProps = {
        messages,
        botTyping,
        setBotTyping,
        avatars,
        currentTheme,
        chatbotWidth,
        sendIcon,
        setSearchQuery,
        searchQuery,
        sendChatbotMessage,
        setMessages,
    };


    const fetchChatbotData = async () => {
        try {
            // Check local storage for existing bot name and customization data
            const storedBotName = JSON.parse(localStorage.getItem(`botName-${chatbotId}`));
            const storedCustomizationData = JSON.parse(localStorage.getItem(`customTheme-${chatbotId}`));
            const storedCustomizationTimestamp = localStorage.getItem(`customThemeTimestamp-${chatbotId}`);
            const botNameData = JSON.parse(localStorage.getItem(`botName-${chatbotId}`));
            const botName = botNameData ? botNameData.name : null;
            const newAvatar = JSON.parse(localStorage.getItem(`newAvatar-${chatbotId}`))

            // Set the bot name again
            setBotName(botName);

            const greetingsData = JSON.parse(localStorage.getItem(`greetings-${chatbotId}`));
            const greetings = greetingsData ? greetingsData : [];

            // Handle greetings
            if (Array.isArray(greetings) && greetings.length > 0) {
                const greeting = greetings.map(greet => ({
                    text: typeof greet === 'object' ? greet.text : greet,
                    type: 'bot'
                }));
                setMessages(greeting);
            }


            const now = Date.now();
            const isCustomizationExpired = storedCustomizationTimestamp && (now - parseInt(storedCustomizationTimestamp) > 43200000); // 12 hours in milliseconds

            // If data is still valid, use it instead of fetching
            if (storedBotName && storedCustomizationData && !isCustomizationExpired) {

                // Retrieve each item from localStorage and parse it
                const customThemeData = JSON.parse(localStorage.getItem(`customTheme-${chatbotId}`));
                const headerData = JSON.parse(localStorage.getItem(`selectedHeader-${chatbotId}`));
                const messageAreaData = JSON.parse(localStorage.getItem(`selectedMessageArea-${chatbotId}`));
                const inputBoxData = JSON.parse(localStorage.getItem(`selectedInputBox-${chatbotId}`));
                const widthData = JSON.parse(localStorage.getItem(`width-${chatbotId}`));
                const themeNameData = JSON.parse(localStorage.getItem(`themeName-${chatbotId}`));
                const iconData = JSON.parse(localStorage.getItem(`icon-${chatbotId}`));
                const taglineData = JSON.parse(localStorage.getItem(`tagline-${chatbotId}`) || 'I am an assistant Bot');
                const newAvatar = JSON.parse(localStorage.getItem(`newAvatar-${chatbotId}`))

                // Set the variables again
                const newCustomTheme = customThemeData ? customThemeData.theme : null;
                const header = headerData ? headerData.header : null;
                const messageArea = messageAreaData ? messageAreaData.messageArea : null;
                const inputBox = inputBoxData ? inputBoxData.inputBox : null;
                const width = widthData ? widthData.width : null;
                const themeName = themeNameData ? themeNameData.themeName : null;
                const icon = iconData ? iconData.icon : null;
                const taglineGot = taglineData.tagLineGot;


                const botAvatar = newAvatar.botAvatar;


                console.log("avatrar", botAvatar)


                // Example of setting these variables to functions
                setCustomThemes(newCustomTheme);
                setHeaderId(header);
                setMessageAreaId(messageArea);
                setInputBoxId(inputBox);
                setChatbotWidth(width);
                setCurrentTheme(newCustomTheme);
                setSendIcon(globalIcons[icon] || globalIcons.sendIcon);
                setTagLine(taglineGot)
                handleAvatarChange('botAvatar', botAvatar)

                return; // Exit early if using cached data
            }

            // If the cache is empty or expired, fetch new data
            setIsLoading(true);
            const response = await axios.post(`https://api-bny5h3g2lq-uc.a.run.app/api/chatbot/${chatbotId}`, {
                uid, apiToken
            });

            if (response.data) {
                const botName = response.data.botName;
                setBotName(botName);

                // Save bot name to localStorage
                localStorage.setItem(`botName-${chatbotId}`, JSON.stringify({ name: botName, timestamp: new Date().toISOString() }));

                // Handle greetings
                if (response.data.greetings && Array.isArray(response.data.greetings)) {
                    const greeting = response.data.greetings.map(greet => ({
                        text: greet,
                        type: 'bot'
                    }));
                    setMessages(greeting);
                    localStorage.setItem(`greetings-${chatbotId}`, JSON.stringify(greeting)); // Save greetings to localStorage
                }

                // Handle customization
                if (response.data.customization) {
                    const customizationData = response.data.customization;
                    const removeQuotes = (value) => (typeof value === 'string' ? value.replace(/"/g, '') : value);

                    const newCustomTheme = customizationData.customTheme || colors;
                    const icon = removeQuotes(customizationData.icon || sendIcon);
                    const header = removeQuotes(customizationData.selectedHeader || 'basic');
                    const messageArea = removeQuotes(customizationData.selectedMessageArea || 'basic');
                    const inputBox = removeQuotes(customizationData.selectedInputBox || 'basic');
                    const themeName = removeQuotes(customizationData.themeName || 'default');
                    const tagLineGot = removeQuotes(customizationData.tagline || 'I am an Assistant Bot');
                    const botAvatar = removeQuotes(customizationData.newAvatar || '')

                    const width = customizationData.width || '360px';

                    // Set states and save each item to localStorage with a namespaced key and timestamp
                    const timestamp = new Date().toISOString(); // Current timestamp
                    setCustomThemes(newCustomTheme);
                    localStorage.setItem(`customTheme-${chatbotId}`, JSON.stringify({ theme: newCustomTheme, timestamp }));
                    localStorage.setItem(`customThemeTimestamp-${chatbotId}`, Date.now().toString()); // Save timestamp for expiry check

                    setHeaderId(header);
                    localStorage.setItem(`selectedHeader-${chatbotId}`, JSON.stringify({ header, timestamp }));

                    setMessageAreaId(messageArea);
                    localStorage.setItem(`selectedMessageArea-${chatbotId}`, JSON.stringify({ messageArea, timestamp }));

                    setInputBoxId(inputBox);
                    localStorage.setItem(`selectedInputBox-${chatbotId}`, JSON.stringify({ inputBox, timestamp }));

                    setChatbotWidth(width);
                    localStorage.setItem(`width-${chatbotId}`, JSON.stringify({ width, timestamp }));

                    setCurrentTheme(newCustomTheme);
                    localStorage.setItem(`themeName-${chatbotId}`, JSON.stringify({ themeName, timestamp }));

                    setSendIcon(globalIcons[icon] || globalIcons.sendIcon);
                    localStorage.setItem(`icon-${chatbotId}`, JSON.stringify({ icon, timestamp }));

                    setTagLine(tagLineGot)
                    localStorage.setItem(`tagline-${chatbotId}`, JSON.stringify({ tagLineGot, timestamp }));

                    handleAvatarChange('botAvatar', botAvatar)
                    localStorage.setItem(`newAvatar-${chatbotId}`, JSON.stringify({ botAvatar, timestamp }));


                    console.log("Customization data saved to localStorage");

                } else {
                    // Set default values if no customization is received
                    const newCustomTheme = colors;
                    const icon = sendIcon;
                    const header = 'basic';
                    const messageArea = 'basic';
                    const inputBox = 'basic';
                    const themeName = 'default';
                    const width = '360px';
                    const tagline = 'I am an assistant Bot';
                    const botAvatar = ''

                    // Set states and save defaults to localStorage
                    const timestamp = new Date().toISOString(); // Current timestamp
                    setCustomThemes(newCustomTheme);
                    localStorage.setItem(`customTheme-${chatbotId}`, JSON.stringify({ theme: newCustomTheme, timestamp }));
                    localStorage.setItem(`customThemeTimestamp-${chatbotId}`, Date.now().toString()); // Save timestamp for expiry check

                    setHeaderId(header);
                    localStorage.setItem(`selectedHeader-${chatbotId}`, JSON.stringify({ header, timestamp }));

                    setMessageAreaId(messageArea);
                    localStorage.setItem(`selectedMessageArea-${chatbotId}`, JSON.stringify({ messageArea, timestamp }));

                    setInputBoxId(inputBox);
                    localStorage.setItem(`selectedInputBox-${chatbotId}`, JSON.stringify({ inputBox, timestamp }));

                    setChatbotWidth(width);
                    localStorage.setItem(`width-${chatbotId}`, JSON.stringify({ width, timestamp }));

                    setCurrentTheme(newCustomTheme);
                    localStorage.setItem(`themeName-${chatbotId}`, JSON.stringify({ themeName, timestamp }));

                    setSendIcon(globalIcons[icon] || globalIcons.sendIcon);
                    localStorage.setItem(`icon-${chatbotId}`, JSON.stringify({ icon, timestamp }));

                    setTagLine(tagline)
                    localStorage.setItem(`tagline-${chatbotId}`, JSON.stringify({ tagline, timestamp }));

                    handleAvatarChange('botAvatar', botAvatar)
                    localStorage.setItem(`newAvatar-${chatbotId}`, JSON.stringify({ botAvatar, timestamp }));


                    console.log("Default customization data saved to localStorage");
                }
            }
        } catch (error) {
            console.error('Error fetching chatbot custom data:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };




    useEffect(() => {
        // Check if customization data is already available in local storage
        fetchChatbotData(); // Fetch if customization data is not available
    }, [chatbotId]);





    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages, loading, botTyping]);

    const renderHeader = () => {
        switch (headerId) {
            case 'Basic':
                return <BasicHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Simple':
                return <StandardHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            // case 'Grand':
            //     return <GrandHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Minimal':
                return <MinimalHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Standard':
                return <GradientHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Iconic':
                return <IconicHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Wave':
                return <WaveHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Diagonal':
                return <DiagonalHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Curved':
                return <CurvedGradientHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            // case 'DarkBoldWave':
            //     return <DarkBoldWaveHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Elegant':
                return <SimpleHeader currentTheme={currentTheme} botName={botName} avatars={avatars} tagline={tagline} />;
            // case 'Chat':
            //     return <ChatHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            default:
                return <BasicHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
        }
    };

    const renderBody = () => {
        switch (messageAreaId) {
            case 'Basic Dialogue Bubble':
                return <BasicChatArea {...commonTemplateProps} />;
            case 'Standard Dialogue Bubble':
                return <StandardChatArea {...commonTemplateProps} />;
            // case 'BubbleShape':
            //     return <BubbleShapedChatArea {...commonTemplateProps} />;
            case 'Bordered Dialogue Bubble':
                return <BorderChatArea {...commonTemplateProps} />;
            // case 'Border2Chat':
            //     return <Border2ChatArea {...commonTemplateProps} />;
            case 'Classic Dialogue Bubble':
                return <TriangleStyle {...commonTemplateProps} />;
            default:
                return <BasicChatArea {...commonTemplateProps} />;
        }
    };

    const renderFooter = () => {
        switch (inputBoxId) {
            case 'Basic':
                return <BasicChatInput {...commonTemplateProps} />;
            case 'Formal':
                return <FormalChatInput {...commonTemplateProps} />;
            case 'Minimal':
                return <MinimalChatInput {...commonTemplateProps} />;
            case 'Standard':
                return <StandardChatInput {...commonTemplateProps} />;
            default:
                return <BasicChatInput {...commonTemplateProps} />;
        }
    };


    useEffect(() => {
        // Function to handle link clicks
        const handleLinkClick = (event) => {
            // Prevent the default action
            event.preventDefault();

            // Open the link in a new tab
            window.open(event.currentTarget.href, '_blank', 'noopener,noreferrer');
        };

        // Add event listeners to all links after the component mounts
        const links = document.querySelectorAll('a');
        links.forEach(link => {
            link.addEventListener('click', handleLinkClick);
        });

        // Clean up listeners on component unmount
        return () => {
            links.forEach(link => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, []);

    document.querySelectorAll('a').forEach((link) => {
        link.addEventListener('click', (event) => {
            // Prevent default action of the link
            event.preventDefault();
            event.stopPropagation(); // Stop the event from bubbling up

            // Open link in new tab
            window.open(link.href, '_blank', 'noopener,noreferrer');
        });
    });
    useEffect(() => {
        const links = document.querySelectorAll('a');

        links.forEach((link) => {
            link.onclick = (event) => {
                // Allow default action but open in a new tab
                event.stopPropagation(); // Prevent bubbling to iframe
                window.open(link.href, '_blank', 'noopener,noreferrer');
                return false; // Prevent default action
            };
        });
    }, [messages]);

    // useEffect(() => {
    //     // Fetch and set the customization data when the component mounts or chatbotId changes
    //     const storedCustomizationData = getCustomizationData();
    //     setCustomizationData(prevData => ({
    //         ...prevData,
    //         ...storedCustomizationData, // Merge with existing state
    //     }));
    // }, [chatbotId]);




    return (
        <>
            <div>
                <div className="ml-60 flex min-h-screen items-center justify-center h-fit" style={{ marginBottom: '5px' }}>
                    {/* Main Content Wrapper */}
                    <div className="flex flex-col items-center w-full max-w-5xl px-4">
                        <div className="shadow-2xl rounded-xl overflow-hidden relative bg-white border-4 border-gray-400 h-fit"
                            style={{ width: chatbotWidth }}>

                            {/* Loading Indicator */}
                            {botLoading ? (
                                <div style={{ width: chatbotWidth, height: '500px' }}>
                                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-gray-600"></div>
                                        <span className="ml-2 text-gray-600 font-semibold">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {/* Content to display once loading is complete */}
                                    {renderHeader()}
                                    {renderBody()}
                                    {/* Powered by Botanion */}
                                    {renderFooter()}
                                    <div className='h-9'></div>
                                    <div className="absolute bottom-0 left-0 w-full bg-gray-100 text-gray-500 text-sm text-center py-2 border-t border-gray-300">
                                        &copy; Powered by <a href="https://botanion.cc" className="text-blue-500 hover:underline">Botanion</a>
                                    </div>
                                    <div ref={messagesEndRef} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomizeAIChatBotDesign;
